.containerOrder {
    background-color: var(--background-bar);
    width: 31vw;

    @media (max-width: 768px) {
        display: none;
    }

    .containerItems {
        width: 100%;
        height: 395px;
    }

    .btn {
        width: 70%;
        border-radius: 20px;
        height: 40px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        font-weight: bold;
        color: #222;
        background-image: linear-gradient(
          to right,
          #462523 0,
          #cb9b51 22%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        );
        border: none;
        cursor: pointer;
    }

    .btn2 {
        width: 70%;
        border-radius: 20px;
        height: 40px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        color: #F1F1F1;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        border: none;
        cursor: pointer;
    }

    .containerItemsScroll {
        width: 100%;
        height: fit-content;
    }

    .containerItemsScroll::-webkit-scrollbar {
        width: 0.4em;
    }

    .containerItemsScroll::-webkit-scrollbar-thumb {
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        border-radius: 10px;
    }

    .precio {
        margin-left: 18px;
        bottom: 50px;
        width: 90%;

        .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.order {
    margin-left: 17px;
    margin-top: 30px;
    color: var(--text-secondary-black);
}

.Advertencia {
    display: flex;
    border: 1px solid #F3E5B4;
    background-color: #FFF5D2;
    border-radius: 15px;
    margin: 15px;
    align-items: center;

    img {
        width: 24px;
        height: 24px;
        margin: 8px;
    }

    p {
        color: #DFAF00;
        font-size: 13px;
        margin: 8px;
    }
}

.confirm {
    margin-left: 20px;
    color: var(--text-secondary-black);
}

.infoClient {
    margin-left: 20px;
    margin-top: 20px;

    .infoClientQuest {
        color: var(--text-secondary-grey);
        font-weight: bold;
        font-size: 15px;
    }

    .infoClientResp {
        color: var(--text-secondary-black);
        font-weight: bold;
        font-size: 16px;
    }
}

.contList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 25px auto 0;
    color: var(--text-secondary-black);

    svg {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {fill: var(--text-secondary-black);}
    }
}

.flex h4{
    color: var(--text-secondary-black);
}

.flex p {
    color: var(--text-secondary-black);
}

.buttonMobile {
    display: none;

    .containerText {
        padding: 10px 0;
        font-size: 14px;
        font-weight: bold;
    }

    svg {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {fill: var(--text-secondary-black);}
    }

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 15px;
        color: #F1F1F1;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
        border: none;
        width: 120px;
        border-radius: 20px;
        height: 50px;
        margin: auto;
        position: fixed;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }
}

.containerOrderMobile {
    width: 100%;

    .containerItems {
        width: 100%;
        height: fit-content;
    }

    .btn {
        width: 70%;
        border-radius: 20px;
        height: 40px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        color: #F1F1F1;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        border: none;
        cursor: pointer;
    }

    .btn2 {
        width: 70%;
        border-radius: 20px;
        height: 40px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        color: #F1F1F1;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        border: none;
        cursor: pointer;
    }

    .containerItemsScroll {
        width: 100%;
        height: fit-content;
    }

    .containerItemsScroll::-webkit-scrollbar {
        width: 0.4em;
    }

    .containerItemsScroll::-webkit-scrollbar-thumb {
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        border-radius: 10px;
    }

    .precio {
        margin-left: 18px;
        bottom: 50px;
        width: 90%;

        .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.order {
    margin-left: 17px;
    margin-top: 30px;
    color: var(--text-secondary-black);
}

.Advertencia {
    display: flex;
    border: 1px solid #F3E5B4;
    background-color: #FFF5D2;
    border-radius: 15px;
    margin: 15px;
    align-items: center;

    img {
        width: 24px;
        height: 24px;
        margin: 8px;
    }

    p {
        color: #DFAF00;
        font-size: 13px;
        margin: 8px;
    }
}

.confirm {
    margin-left: 20px;
    color: var(--text-secondary-black);
}

.infoClient {
    margin-left: 20px;
    margin-top: 20px;

    .infoClientQuest {
        color: var(--text-secondary-grey);
        font-weight: bold;
        font-size: 15px;
    }

    .infoClientResp {
        color: var(--text-secondary-black);
        font-weight: bold;
        font-size: 16px;
    }
}

.contList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 25px auto 0;
    color: var(--text-secondary-black);

    svg {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {fill: var(--text-secondary-black);}
    }
}

.flex h4{
    color: var(--text-secondary-black);
}

.flex p {
    color: var(--text-secondary-black);
}

.buttonMobile {
    display: none;

    .containerText {
        padding: 10px 0;
        font-size: 14px;
        font-weight: bold;
    }

    svg {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {fill: var(--text-secondary-black);}
    }

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 15px;
        color: #F1F1F1;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        border: none;
        width: 120px;
        border-radius: 20px;
        height: 50px;
        margin: auto;
        position: fixed;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }
}