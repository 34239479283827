.itemContainer {
    width: 94%;
    height: 100px;
    background-color: var(--background);
    margin: 10px;
    display: flex;
    border-radius: 6px;
    justify-content: space-between;


    .item {
        display: flex;
    }

    .vector {
        width: 15px;
        height: 15px;
        cursor: pointer;
        right: 23px;
    }

    img {
        width: 80px;
        height: 80px;
        margin: 10px;
        border-radius: 6px;
        object-fit: cover;
    }

    .itemInfo {
        margin-top: 5px;

        h3 {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
            color: var(--text-secondary-black)
        }

        p {
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            color: var(--text-secondary-grey)
        }

        .monto {
            background-color: #EBEBEB;
            width: 42px;
            height: 23px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            margin-top: 21px;

            h5 {
                color: var(--text-secondary-grey);
                font-size: 14px;
                font-weight: 500;
                margin: auto;
                text-align: center;
            }
        }
    }
}