/* Sidebar Cerrada e item Activo */
.normal[aria-current] {
    position: relative;
    width: 60px;
    height: 50px;
    color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
    background-color: var(--background-bar);
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 40px;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
        left: 59px;
        top: 6px;
        border-radius: 2px;
    }
}

/* Sidebar Cerrada e item Desactivado */
.normal {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    margin-bottom: 10px;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a:hover {
        path {
            fill: white;
        }
    }
}

/* Sidebar Abierta e item Desactivado */
.linkOpen {
    height: 50px;
    display: flex;
    padding-left: 30px;
    align-items: center;
    gap: 40px;
    transition-duration: 0.3s;
    margin-bottom: 10px;

    p {
        color: var(--text-secondary-grey);
        font-weight: bold;
        font-size: 14px;
        padding-bottom: 2px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* Sidebar Abierta e item Activado */
.linkOpen[aria-current] {
    position: relative;
    height: 50px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 40px;
    transition-duration: 0.3s;
    background-color: var(--background-bar);

    ::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 40px;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        left: 282px;
        top: 6px;
        border-radius: 2px;
        transition-duration: 0.3s;

        @media (max-width: 768px) {
            display: none;
        }
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        font-weight: bold;
        font-size: 14px;
        padding-bottom: 2px;
    }

    p::after {
        display: none;
    }
}

/* Hovers Sidebar Abierta */
.linkOpen:hover {
    p {
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
    }
}