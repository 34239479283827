.sectionCenter {
    display: flex;
    flex-wrap: wrap;
    margin-top: 21px;
    justify-content: center;
    height: fit-content;
    overflow: scroll;
    overflow-x: hidden;
}

.sectionCenter::-webkit-scrollbar {
    width: 0.4em;
}

.sectionCenter::-webkit-scrollbar-thumb {
    color: transparent;
    background-image: linear-gradient(
      to right,
      #462523 0,
      #cb9b51 22%,
      #f6e27a 45%,
      #f6f2c0 50%,
      #f6e27a 55%,
      #cb9b51 78%,
      #462523 100%
    );
    border-radius: 10px;
}

.item {
    width: 232px;
    height: 232px;
    border-radius: 16px;
    margin: 10px;
    box-shadow: var(--box-shadow);

    @media (max-width: 768px) {
        width: 160px;
    }
}

.photo {
    width: 100%;
    height: 153px;
    background-color: var(--background);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    object-fit: cover;
}

.itemInfo {
    padding-left: 10px;

    h3 {
        font-size: 17px;
        color: var(--text-secondary-grey);
    }

    h4 {
        font-size: 15px;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
    }

    button {
        position: relative;
        right: -176px;
        top: -14px;
        width: 35px;
        height: 35px;
        border-radius: 11px;
        border: #AEAEAE 1px solid;
        background-color: var(--background);
        cursor: pointer;

        @media (max-width: 768px) {
            right: -105px;
        }

        &:hover {
            color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );

            svg {
                path {
                    stroke: var(--background);
                }
            }
        }
    }
}

.svgMore {
    position:relative;
    top: 1px;
    left: 1px;
}