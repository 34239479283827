
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: system-ui;
}

#root {
  background-color: var(--background);
}

.flex {
  display: flex;
  background-color: var(--background);
  height: fit-content;

  // media query
  @media (min-width: 1660px) {
    height: 100vh;
  }
}

.logo {
  width: 80px;
}

.containerLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: space-around;
  }
}

.textPiz {
  font-weight: bold;
  color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
}

.textDon {
  font-weight: bold;
  font-size: 17px;
  margin-top: -7px;
  color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
}

.containerText {
  margin-top: 20px;
}