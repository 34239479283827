.btnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;

    svg {
        margin-right: 6px;
        position: relative;
        top: 3px;

        @media (max-width: 768px) {
            width: 45px;
            height: 45px;
            background-color: var(--background-bar);
            padding: 10px;
            border-radius: 10px;
        }
    }

    form input[type="radio"]:checked + .checked{
        border: none;
        border-radius: 5px;
        padding: 5px 0 10px 0;
        font-size: 1rem;
        font-weight: 400;
        fill: var(--text-primary-orange);
        width: 20%;
        cursor: pointer;
        color: transparent;
        background-image: linear-gradient(
          to right,
          #462523 0,
          #cb9b51 22%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        );
        -webkit-background-clip: text;

        path {

            @media (max-width: 768px) {
                fill: var(--background);
            }
        }

        svg {
            @media (max-width: 768px) {
                color: transparent;
                background-image: linear-gradient(
                  to right,
                  #462523 0,
                  #cb9b51 22%,
                  #f6e27a 45%,
                  #f6f2c0 50%,
                  #f6e27a 55%,
                  #cb9b51 78%,
                  #462523 100%
                );
                -webkit-background-clip: text;
            }
        }

        &::after {
                content: "";
                display: block;
                position: relative;
                top: 10px;
                width: 98%;
                height: 4px;
                background-image: linear-gradient(
                  to right,
                  #462523 0,
                  #cb9b51 22%,
                  #f6e27a 45%,
                  #f6f2c0 50%,
                  #f6e27a 55%,
                  #cb9b51 78%,
                  #462523 100%
                );
                border-radius: 2px;
                z-index: 2;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        &:hover {
            color: transparent;
            background-image: linear-gradient(
              to right,
              #462523 0,
              #cb9b51 22%,
              #f6e27a 45%,
              #f6f2c0 50%,
              #f6e27a 55%,
              #cb9b51 78%,
              #462523 100%
            );
            -webkit-background-clip: text;
            &::after {
                content: "";
                display: block;
                position: relative;
                top: 10px;
                width: 98%;
                height: 4px;
                color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
                border-radius: 2px;
                z-index: 2;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
        &:focus {
            color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
            &::after {
                content: "";
                display: block;
                position: relative;
                top: 10px;
                width: 98%;
                height: 4px;
                color: transparent;
                background-image: linear-gradient(
                  to right,
                  #462523 0,
                  #cb9b51 22%,
                  #f6e27a 45%,
                  #f6f2c0 50%,
                  #f6e27a 55%,
                  #cb9b51 78%,
                  #462523 100%
                );
                -webkit-background-clip: text;
                transition: all 0.3s ease-in-out;
                border-radius: 2px;
                z-index: 2;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
    }

    form label {
        color: var(--text-secondary-black);
        font-weight: 400;
    }

    form label span {
        @media (max-width: 768px) {
            display: none;
        }
    }

    form label:hover {
        border: none;
        border-radius: 5px;
        padding: 5px 0 10px 0;
        font-size: 1rem;
        font-weight: 400;
        color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
        width: 20%;
        cursor: pointer;
        &::after {
                content: "";
                display: block;
                position: relative;
                top: 10px;
                width: 98%;
                height: 4px;
                color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-background-clip: text;
                border-radius: 2px;
                z-index: 2;

                @media (max-width: 768px) {
                    display: none;
                }
            }
    }

    form label:hover{
        cursor: pointer;
    }

    form input{
        display: none;
    }
}

.separator {
    width: 100%;
    height: 1px;
    background-color: var(--text-secondary-grey);
    opacity: 0.2;
    margin: 0 10px;
    position: relative;
    top: 10px;
    z-index: 1;

    @media (max-width: 768px) {
        display: none;
    }
}