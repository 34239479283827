.containerMain {
    background-color: var(--background);
    width: 69vw;
    height: fit-content;

    @media (max-width: 768px) {
        width: 100vw;
    }

    h2 {
        font-size: 1.4rem;
        color: var(--text-secondary-black);
        margin-left: 30px;
        padding: 0;
    }

    p {
        font-size: 1.0rem;
        color: var(--text-secondary-grey);
        margin-left: 30px;
        padding: 0;
    }
}