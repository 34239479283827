.searchInputs {
    width: 90%;
    border: 1px solid rgba($color: white, $alpha: 1.0);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--background-bar);
    margin: 30px 0 20px 30px;

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 400px) {
      width: 70%;
    }
  }

  .search {
    @media (max-width: 768px) {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }
  
  .search input {
    border: none;
    outline: none;
    box-shadow: none;
    width: 96%;
    font-size: 16px;
    font-weight: 400;
    padding: 8px 10px;
    background: var(--background-bar);
    color: var(--text-secondary-grey);
  }

  i {
    padding-left: 10px;
    color: var(--text-secondary-grey);
    }
  
  .searchIcon {
    height: 60px;
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    margin-top: -20px;
    width: 604px;
    height: 200px;
    background-color: var(--background);
    box-shadow: var(--box-shadow);
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 200;
    margin-left: 32px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @media (max-width: 768px) {
      width: 310px;
      margin-top: 70px;
    }
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-secondary-grey);
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }
  
  a:hover {
    background-color: var(--background-bar);
  }

  .imagenSearch {
    width: 40px;
    height: 40px;
    border-radius: 25%;
    margin-left: 10px;
  }
  
  #clearBtn {
    cursor: pointer;
  }

  .flexCent {
    display: flex;
    align-items: center;

    .svgMarg {
      margin-right: 10px;

      path:hover {
        fill: var(--text-primary-orange);
        cursor: pointer;
      }
    }
  }

  .svgMore2 {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 11px;
    border: #AEAEAE 1px solid;
    background-color: var(--background);
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      color: transparent;
      background-image: linear-gradient(
        to right,
        #462523 0,
        #cb9b51 22%,
        #f6e27a 45%,
        #f6f2c0 50%,
        #f6e27a 55%,
        #cb9b51 78%,
        #462523 100%
      );

        svg {
            path {
                stroke: var(--background);
            }
        }
    }
}