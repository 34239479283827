:root {
    --background: white;
    --background-bar: #F6F6F6;
    --text-primary-orange: #F2BB02;
    --text-secondary-black: #353535;
    --text-secondary-grey: #8F8F8F;
    --accent: purple;
    --box-shadow: hsla(210,8%,62%,.2) 0px 8px 24px;
  }
  [data-theme='dark'] {
    --background: #1F1F1F;
    --background-bar: #151515;
    --text-primary-orange: #C7AE16;
    --text-secondary-black: #f1f1f1;
    --accent: darkred;
    --box-shadow: #000000 0px 8px 20px;
  }