.container {
    height: 100vh;
    width: 99vw;
    background-color: var(--background);

    h2 {
        margin-top: 25px;
        font-size: 1.4rem;
        color: var(--text-secondary-black);
        margin-left: 30px;
        padding: 0;

        @media (max-width: 768px) {
            margin-top: 75px;
        }
    }

    p{
        margin-left: 30px;
        font-size: 1rem;
        color: var(--text-secondary-grey);
        padding: 0;
    }

    .preguntas {
        margin-top: 20px;

        h3 {
            font-size: 16px;
            margin-left: 30px;
            color: var(--text-secondary-black);
        }

        .containerQuestion {
            margin-top: 8px;

            .pregunta {
                font-size: 1rem;
                color: var(--text-secondary-black);
                padding: 0;
            }
        }
    }

    .reportar {
        margin-top: 10px;

        form{
        display: flex;
        flex-direction: column;}

        h3 {
            font-size: 16px;
            margin-left: 30px;
            color: var(--text-secondary-black);
        }

        input {
            margin-left: 30px;
            margin-top: 8px;
            width: 60%;
            border: none;
            outline: none;
            box-shadow: none;
            border-radius: 5px;
            padding: 8px 10px;
            font-size: 16px;
            font-weight: 400;
            background-color: var(--background-bar);
            color: var(--text-secondary-grey);
        }

        textarea {
            margin-left: 30px;
            margin-top: 10px;
            width: 60%;
            border: none;
            outline: none;
            box-shadow: none;
            border-radius: 5px;
            padding: 8px 10px;
            font-size: 16px;
            font-weight: 400;
            background-color: var(--background-bar);
            color: var(--text-secondary-grey);
            resize: none;
        }

        button {
            border: none;
            border-radius: 5px;
            padding: 8px 10px;
            font-size: 1rem;
            background-image: linear-gradient(
              to right,
              #462523 0,
              #cb9b51 22%,
              #f6e27a 45%,
              #f6f2c0 50%,
              #f6e27a 55%,
              #cb9b51 78%,
              #462523 100%
            );
            color: #f1f1f1;
            margin-left: 30px;
            margin-top: 8px;
            width: 60%;
        }
    }
}