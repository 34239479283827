.container {
    height: 100vh;
    width: 99vw;
    background-color: var(--background);

    h2 {
        margin-top: 25px;
        font-size: 1.4rem;
        color: var(--text-secondary-black);
        margin-left: 30px;
        padding: 0;

        @media (max-width: 768px) {
            margin-top: 75px;
        }
    }

    p {
        font-size: 1rem;
        color: var(--text-secondary-grey);
        margin-left: 30px;
        padding: 0;
    }

    .containerInputs2 {
        margin-left: 30px;

        h5 {
            color: var(--text-secondary-grey);
        }
    }

    .containerInputs {
        margin-left: 30px;
        margin-top: 15px;

        h5 {
            color: var(--text-secondary-grey);
        }

        input {
            margin-top: 10px;
            width: 60%;
            border: none;
            outline: none;
            box-shadow: none;
            border-radius: 5px;
            padding: 8px 10px;
            font-size: 16px;
            font-weight: 400;
            background-color: var(--background-bar);
            color: var(--text-secondary-grey);
        }

        button {
            margin-top: 10px;
            border: none;
            border-radius: 5px;
            padding: 8px 10px;
            font-size: 1rem;
            color: transparent;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
            color: #f1f1f1;
        }
    }

    .wrapper{
        width: 130px;
        height: 40px;
        margin-top: 10px;
        }
        
        .switch{
        position: relative;
        width: 126px;
        height: 35px;
        margin: 0px;
        appearance: none;
        -webkit-appearance: none;
          
        background-color: #353535;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 25px;
        box-shadow: inset 0px 0px 2px rgba(255,255,255,.7);
        transition: background-image .7s ease-in-out;
        outline: none;
        cursor: pointer;
        overflow: hidden;
        }
        
        .switch:checked{
        background-color: #F6F6F6;
        box-shadow: inset 0px 0px 2px rgb(141 163 146 / 75%);
        background-size: cover;
        transition: background-image 1s ease-in-out;
        }
        
        
        .switch:after{
        content: '';
        width: 46px;
        height:46px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        left: 0px;
        top: -4px;
        transform: translateX(0px);
        animation: off .7s forwards cubic-bezier(.8, .5, .2, 1.4);
        box-shadow: inset 5px -5px 4px rgba(53, 53, 53, 0.3);
        }
        
        @keyframes off{
        0%{
            transform: translateX(80px);
            width: 46px;
        }
        50%{
            width: 75px;  
            border-radius: 25px;   
        }
        100%{
            transform: translateX(0px); 
            width: 46px;
        }
        }
        
        
        
        .switch:checked:after{
        animation: on .7s forwards cubic-bezier(.8, .5, .2, 1.4);
        box-shadow: inset -5px -5px 4px rgba(53, 53, 53, 0.3);
        }
        
        @keyframes on{
        0%{
            transform: translateX(0px);
            width: 46px;
        }
        50%{
            width: 75px;  
            border-radius: 25px;   
        }
        100%{
            transform: translateX(80px); 
            width: 46px;
        }
        }
        
        
        .switch:checked:before{
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: absolute;
        left: 15px;
        top: 5px;
        transform-origin: 53px 10px;
        background-color: transparent;
        box-shadow: 5px -1px 0px #fff;
        filter: blur(0px);
        animation: sun .7s forwards ease;
        }
        @keyframes sun{
        0%{
            transform: rotate(170deg);
            background-color: transparent;
            box-shadow: 5px -1px 0px #fff;
            filter: blur(0px);
        }
        50%{
            background-color: transparent;
            box-shadow: 5px -1px 0px #fff;
            filter: blur(0px);
        }
        90%{
            background-color: #f5daaa;
            box-shadow: 0px 0px 10px #f5deb4,
            0px 0px 20px #f5deb4,
            0px 0px 30px #f5deb4,
             inset 0px 0px 2px #efd3a3;
             filter: blur(1px);
        }
        100%{
            transform: rotate(0deg);
            background-color: #f5daaa;
            box-shadow: 0px 0px 10px #f5deb4,
            0px 0px 20px #f5deb4,
            0px 0px 30px #f5deb4,
             inset 0px 0px 2px #efd3a3;
             filter: blur(1px);
        }
        }
        
        
        
        .switch:before{
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: absolute;
        left: 15px;
        top: 5px;
        filter: blur(1px);
        background-color: #f5daaa;
        box-shadow: 0px 0px 10px #f5deb4,
        0px 0px 20px #f5deb4,
        0px 0px 30px #f5deb4,
         inset 0px 0px 2px #efd3a3;
         transform-origin: 53px 10px;
         animation: moon .7s forwards ease;
        }
        @keyframes moon{
        0%{
            transform: rotate(0deg);
            filter: blur(1px);
        }
        50%{
            filter: blur(1px);
        }
        90%{
            background-color: transparent;
            box-shadow: 5px -1px 0px #fff;
            filter: blur(0px);
        
        }
        100%{
            transform: rotate(170deg);
            background-color: transparent;
            box-shadow: 5px -1px 0px #fff;
            filter: blur(0px);
        }
    }

    
}