.container {
    background-color: var(--background);
    width: 69vw;
    height: fit-content;

    h2 {
        margin-top: 25px;
        font-size: 1.4rem;
        color: var(--text-secondary-black);
        margin-left: 30px;
        padding: 0;

        @media (max-width: 768px) {
            margin-top: 75px;
        }
    }

    p {
        font-size: 1rem;
        color: var(--text-secondary-grey);
        margin-left: 30px;
        padding: 0;
    }

    .itemsContainer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 30px;
    }
}