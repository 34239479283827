.sidebar {
    width: 65px;
    background-color: var(--background-bar);
    transition-duration: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        display: none;
    }

    .hamburger {
        width: 25px;
        margin-top: 35px;
        cursor: pointer;
    }

    .linksContainer {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }
}

.sidebarOpen {
    width: 366px;
    background-color: var(--background-bar);
    transition-duration: 0.3s;

    .linksContainer {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    a {
        text-decoration: none;
    }

    .hamburger {
        width: 25px;
        margin-top: 20px;
        cursor: pointer;
        margin-left: 17.5px;
    }

    @media (max-width: 768px) {
        position: fixed;
        z-index: 999;
        height: 100vh;
    }
}

.hamburgerMobile {
    width: 25px;
    margin-top: 35px;
    cursor: pointer;
    display: none;

    @media (max-width: 768px) {
        display: block;
        position: absolute;
        left: 30px;
    }
}

.hamburgerMobileDisplay {
    width: 25px;
    margin-top: 35px;
    cursor: pointer;
    display: none;

    @media (max-width: 768px) {
        display: block;
        position: absolute;
        left: 30px;
    }
}